export const ASSET_TYPE_FIELDS:any =[{
  label: "Select Asset Type",
  name: "selectAssetForm",
  type: "select-picker",
  fieldProps: {
      isMulti: false,
      initialOptions: [
          {
              value: "image",
              label: "Digital Image Asset",
          },
          {
              value: "3d",
              label: "3D Asset",
          },
          {
              value: "audio",
              label: "Audio Asset",
          },
          {
              value: "video",
              label: "Video Asset",
          },
          
          
      ],
      
  },
}]

export const  IMAGE_FIELDS: any = [
  {
    type: "choosePhoto",
    compProps: {
      id: "asset",
      label: "Upload Primary Asset",
      required: true,
      title:"Select Asset",
    },
  },
   {
   type: "imageDropZone",
   compProps: {
     id: "supportingAssets",
     label: "Upload Supporting Assets",
    //  required: true,
   },
 },
    {
   type: "text",
   compProps: {
      id: "name",
      label: "Name",
      required: true,
    },
  },
  {
    type: "htmlEditor",
    compProps: {
      id: "description",
      label: "Description",
      required: true,
    },
  },

//  {
//    type: "imageDropZone",
//    compProps: {
//      id: "primaryAssets",
//      label: "Select Avatars",
//      required: true,
//    },
//  },
 {
  type: "radio",
  compProps: {
    id: "rarity",
    label: "Select Rarity Category",
    required: true,
    options:['Platinum','Diamond','Gold']
  },
},

{
  type: "bool",
  compProps: {
     id: "digitaltwin",
     label: "is Digital Twin",
     required: true,
   },
 },

  {
    type: "dynamicFields",
    compProps: {
      id: "attributes",
      label: "Attributes",
      // required: true,
    },
  },
];

export const  AUDIO_FIELDS: any = [
  {
    type: "fileDropZone",
    compProps: {
      id: "asset",
      label: "Upload Primary Asset",
      required: true,
      title:"Select Asset",
      maxFiles:1,
      maxSize:'10mb'
    },
  },
  {
    type: "choosePhoto",
    compProps: {
      id: "audioPoster",
      label: "Upload Audio Poster",
      title:"Upload Audio Poster",
    },
  },
  {
    type: "fileDropZone",
    compProps: {
      id: "supportingAssets",
      label: "Upload Supporting Assets",
     //  required: true,
     maxFiles:5,
     maxSize:'10mb'
    },
  },
    {
   type: "text",
   compProps: {
      id: "name",
      label: "Name",
      required: true,
    },
  },
  {
    type: "htmlEditor",
    compProps: {
      id: "description",
      label: "Description",
      required: true,
    },
  },

 {
  type: "radio",
  compProps: {
    id: "rarity",
    label: "Select Rarity Category",
    required: true,
    options:['Platinum','Diamond','Gold']
  },
},

{
  type: "bool",
  compProps: {
     id: "digitaltwin",
     label: "is Digital Twin",
     required: true,
   },
 },

  {
    type: "dynamicFields",
    compProps: {
      id: "attributes",
      label: "Attributes",
      // required: true,
    },
  },
];
export const  VIDEO_FIELDS: any= [
  {
    type: "fileDropZone",
    compProps: {
      id: "asset",
      label: "Upload Primary Asset",
      required: true,
      title:"Select Asset",
      maxFiles:1,
      maxSize:'10mb'
    },
  },
  {
    type: "choosePhoto",
    compProps: {
      id: "videoPoster",
      label: "Upload Video Poster",
      title:"Upload Video Poster",
    },
  },
  {
    type: "fileDropZone",
    compProps: {
      id: "supportingAssets",
      label: "Upload Supporting Assets",
     //  required: true,
     maxFiles:5,
     maxSize:'10mb'
    },
  },
    {
   type: "text",
   compProps: {
      id: "name",
      label: "Name",
      required: true,
    },
  },
  {
    type: "htmlEditor",
    compProps: {
      id: "description",
      label: "Description",
      required: true,
    },
  },

 {
  type: "radio",
  compProps: {
    id: "rarity",
    label: "Select Rarity Category",
    required: true,
    options:['Platinum','Diamond','Gold']
  },
},

{
  type: "bool",
  compProps: {
     id: "digitaltwin",
     label: "is Digital Twin",
     required: true,
   },
 },

  {
    type: "dynamicFields",
    compProps: {
      id: "attributes",
      label: "Attributes",
      // required: true,
    },
  },
];
